import React, { useState, useEffect } from 'react'
import CX from 'classnames'
import './index.less'

export default function SelectCustom({
    chooseList = ['A', 'B', 'C', 'D', 'E'],
    style = {},
    onClick = () => {},
    currentIndex = 0
}) {
    const [activeIndex, setActiveIndex] = useState(currentIndex)
    const wrapStyle = Object.assign({
        width: 320, height: 30
    }, style)
    useEffect(() => {
        setActiveIndex(currentIndex)
    }, [currentIndex])
    return (
        <div className="select-wrap" style={wrapStyle}>
            {
                chooseList.map((item, index) => {
                    return (
                        <div
                            key={'select' + index}
                            className={CX({
                                active: index === activeIndex,
                                normal: true,
                                'items-center': true,
                                'justify-center': true,
                            })}
                            onClick={() => {
                                setActiveIndex(index)
                                onClick(index)
                            }}
                        >{item}</div>
                    )
                })
            }
        </div>
    )
}