import React, { useContext } from 'react'
import './index.less'

import { DrawPageStateContext, HotAreaConfigContext } from '@/context'
export default function HotAreaHoc({
    editContentSize,
    editContent,
    contentFit = 'contain',
    defaultValue = [],
    onSave,
    style = {},
}) {
    const { setShowHotEdit } = useContext(DrawPageStateContext)
    const { setContentSize, setContent, setSaveCb, setDefaultValue, setContentFit } = useContext(HotAreaConfigContext)
    const wrapStyle = Object.assign({ width: 320, height: 162 }, style)
    return (
        <div style={wrapStyle} className="hot-area-hoc items-center justify-center flex-col">
            <div>编辑热区</div>
            <div className="button items-center justify-center" onClick={() => {
                setShowHotEdit(true)
                setContentSize(editContentSize)
                setContent(editContent)
                setSaveCb(onSave)
                setDefaultValue(defaultValue)
                setContentFit(contentFit)
            }}>展开画布</div>
        </div>
    )
}