import React, { useState, useEffect } from 'react'
import { Slider } from '@/components'

export default function SliderHOC(props) {
    const [value, setValue] = useState(props.defaultValue)
    useEffect(() => {
        setValue(props.defaultValue * 3)
    }, [props.defaultValue])
    return (
        <Slider
            {...props}
            max={props.max * 3}
            min={props.min * 3}
            defaultValue={value}
            onChange={(v) => {
                setValue(v)
                props.onChange(v / 3)
            }}
        />
    )
}