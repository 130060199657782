import superagent from 'superagent'
import { encrypt } from '@/utils/des'
import { host, tokenPrefix } from './host'

// 画布页面！！！

const registUser = ({ user_name, password, shop_id }) => {
    return new Promise((resolve, reject) => {
        superagent.post(`${host}/v1/user/create`)
        .set('token', encrypt(`${tokenPrefix}.user`))
        .send({
            user_name,
            password,
            shop_id,
            create_time: Date.now() + '',
            whitelist: '[]',
        })
        .then((res) => {
            if (res.body.err === 0) {
                resolve(res.body.data.user_id)
            }
        }, (err) => {
            reject(err)
        })
    })
}

const getUserInfoById = (user_id) => {
    return new Promise((resolve, reject) => {
        getUserList({ user_id }, 1, 1).then((res) => {
            resolve(res[0])
        }, (err) => reject(err))
    })
}

const getUserList = (matches = {}, page = 1, size = 1000) => {
    return new Promise((resolve, reject) => {
        superagent.post(`${host}/v1/user/list/${page}/${size}`)
            .set('token', encrypt(`${tokenPrefix}.user`))
            .send(matches)
            .then((res) => {
                if (res.body.err === 0) {
                    resolve(res.body.data)
                }
            }, (err) => {
                reject(err)
            })
    })
}

const login  = (user_name = '', password = '') => {
    return new Promise((resolve, reject) => {
        getUserList({ user_name, password }).then((data) => {
            if (data.length > 0) {
                resolve(data[0])
            } else {
                reject()
            }
        }, (err) => reject(err))
    })
}

export {
    registUser,
    getUserList,
    getUserInfoById,
    login,
}
