import './App.less';
import { ConfigProvider } from 'antd'
import { Routes, Route, HashRouter } from 'react-router-dom';
import MainPage from './pages/MainPage'
import Login from './pages/Login'
import {
  GlobalStore,
  PluginProvider,
  InsertProvider,
  BaseConfig,
  DrawPageState,
  HotAreaConfig,
  Tips,
  UserInfo,
  ModulesProvider,
  AdvertProvider
} from '@/context/provider'

function App() {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          components: {
            Input: {},
            Slider: {
              railBg: '#f5f5f5',
              railHoverBg: '#f5f5f5',
              trackBg: '#477AFF',
              trackHoverBg: '#477AFF',
              dotActiveBorderColor: '#477AFF',
              dotBorderColor: '#477AFF',
              handleColor: '#477AFF',
            }
          },
        }}
      >
        <HashRouter>
          <GlobalStore>
            <ModulesProvider>
              <UserInfo>
                <Tips>
                  <DrawPageState>
                    <BaseConfig>
                      <HotAreaConfig>
                        <PluginProvider>
                          <InsertProvider>
                            <AdvertProvider>
                              <Routes>
                                <Route path="/" element={<Login />} />
                                <Route path="/main" element={<MainPage />} />
                                {/* <Route path="/preview" element={<Preview />} /> */}
                              </Routes>
                            </AdvertProvider>
                          </InsertProvider>
                        </PluginProvider>
                      </HotAreaConfig>
                    </BaseConfig>
                  </DrawPageState>
                </Tips>
              </UserInfo>
            </ModulesProvider>
          </GlobalStore>
        </HashRouter>
      </ConfigProvider>
    </div>
  );
}

export default App;
