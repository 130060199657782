import React, { useContext, useState, useMemo, useEffect } from 'react'
import {
    PluginContext,
    InsertContext,
    DrawPageStateContext,
    UserInfoContext,
    ModulesContext,
    AdvertProviderContext,
} from '@/context'
import { Dropdown, Space } from 'antd'
import { DownOutlined, LockOutlined, SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import './index.less'
// TODO: 根据用户信息初始化modules, 判断用户能使用哪些module

// 左侧查看案例固定内容
const ADVERT_MESSAGE_ARR = [
    '京东店铺美妆品类案例',
    '京东店铺3C品类案例',
    '京东通天塔品类案例',
    '京东通天塔品牌案例',
]

const MenuItem = ({ content = '', isLock = false }) => {
    return (
        <Space>
            {content}
            {
                isLock && <LockOutlined />
            }
        </Space>
    )
}

// 左侧内容区域
export default function LeftContent() {
    const { userInfo } = useContext(UserInfoContext)
    const { plugins, setPlugins } = useContext(PluginContext)
    // let uniqueArr = plugins.filter((item, index, self) =>index === self.findIndex((t) => t.name === item.name));
    const { setCurrentModule, currentModule } = useContext(DrawPageStateContext)
    const { modules } = useContext(ModulesContext)
    const { caseAdvertInfo } = useContext(AdvertProviderContext)
    const { init } = useContext(InsertContext)
    const [dropTitle, setDropTitle] = useState(currentModule ? currentModule.name : '请选择模块')
    const [largeQR, setLargeQR] = useState('')
    const [activePlugin, setActivePlugin] = useState([])

    useEffect(() => {
        if (currentModule) {
            setDropTitle(currentModule.name)
            // 初始化插件
            setPlugins(currentModule.plugins)

            modules.forEach((item) => {
                if(item.name === currentModule.name) {
                    setActivePlugin(item.plugins)
                }
            })

        }
    }, [currentModule])

    const uniqueArr = useMemo(() => {
        if (activePlugin?.length <= 0) return []
        return activePlugin.filter((item, index, self) =>index === self.findIndex((t) => t.name === item.name));
    }, [activePlugin])

    // 模块列表
    const menuList = useMemo(() => {
        const result = []
        // 添加展示用户白名单里的模块
        for (let i = 0; i < modules.length; i++) {
            const module = modules[i]
            const isLock = !userInfo.whitelist.includes(module.name)

            const menuItem = {
                label: <MenuItem content={module.name} isLock={isLock} />,
                key: `${i}`,
                disabled: isLock,
            }
            if (isLock) {
                result.push(menuItem)
            } else {
                result.unshift(menuItem)
            }
        }
        return result
    }, [modules, userInfo])

    // 选择一个模块
    const handleChooseItem = (e) => {
        let result = {}
        menuList.forEach((item) => {
            if (item.key === e.key) {
                result = item
            }
        })
        if (modules[e.key].name !== dropTitle) {
            // TODO 选中当前模块不需要初始化
            init() // 将画布中的内容清空
            setDropTitle(result.label) // 设置下拉框标题
            setPlugins(modules[e.key].plugins) // 获取当前模块所属的插件
            setCurrentModule(modules[e.key]) // 设置当前模块

            setActivePlugin(modules[e.key].plugins)
        }
    }

    return (
        <div className="left-content-wrap">
            <div className="top-area">
                <img src={require('@/assets/imgs/border.svg').default} alt="" />
                <Dropdown
                    menu={{
                        items: menuList,
                        onClick: handleChooseItem,
                    }}
                    onOpenChange={(e) => {
                    }}
                    style={{
                        maxHeight: 500,
                        width: 1000,
                    }}
                >
                    <div className="choose-wrap">
                        <div className="title">{dropTitle}</div>
                        <DownOutlined style={{ color: 'black', fontSize: 10 }} />
                    </div>
                </Dropdown>
            </div>
            <div className="main-content">
                {
                    uniqueArr.map((plugin, index) => {
                        return (
                            <div
                                key={index + 'LeftContent'}
                                className="plugin-item"
                                draggable="true"
                                onDragStart={(e) => {
                                    e.dataTransfer.setData('type', plugin.type)
                                }}
                            >
                                <div className="name">{plugin.name}</div>
                                <img className="icon" src={require('@/assets/imgs/拖动.svg').default} alt="" />
                            </div>
                        )
                    })
                }
                {
                    uniqueArr.length > 0 && (
                        <div className="plugin-item blue">
                            <div className="name">查看案例</div>
                            <SearchOutlined style={{
                                fontSize: 16,
                                marginRight: 5,
                            }} />
                            <div className="advert-area">
                                <div className="top justify-between items-center">
                                    <div className="title">查看案例</div>
                                    {/* <CloseCircleOutlined style={{ fontSize: 24, cursor: 'pointer' }} /> */}
                                </div>
                                <div className="bottom">
                                    <div className="qr-area justify-around">
                                        {
                                            caseAdvertInfo.map((qr, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        onClick={() => {
                                                            setLargeQR(qr.imgUrl)
                                                        }}
                                                        className="qr-code flex-col items-center"
                                                    >
                                                        <img className="qr-img" src={qr.imgUrl} alt="" />
                                                        <div className="qr-name">{ADVERT_MESSAGE_ARR[index]}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="tips justify-center">
                                        <img src={require('@/assets/imgs/open-jd.png')} style={{
                                            width: 503,
                                            height: 78,
                                        }} />
                                    </div>
                                    {
                                        largeQR !== '' && (
                                            <div className="large-qr flex-col items-center justify-center" onClick={() => {
                                                setLargeQR('')
                                            }}>
                                                <img src={largeQR} alt="" />
                                                点击空白返回
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                        </div>
                    )
                }
                <div className="course-btn items-center justify-center" onClick={() => {
                    window.open('https://ayhfhfqwbf.feishu.cn/wiki/Bnqewo3Iqisq4EkTzLTcg67Inpg')
                }}>
                    <QuestionCircleOutlined style={{
                        marginRight: 2,
                    }} />
                    使用教程
                </div>
            </div>

        </div>
    )
}