import superagent from 'superagent'
import { encrypt } from '@/utils/des'
import { host, tokenPrefix } from './host'

// 保存该模块的记录
const saveHistory = ({ user_id, module_id, data }) => {
    return new Promise((resolve, reject) => {
        superagent.post(`${host}/v1/history/create`)
            .set('token', encrypt(`${tokenPrefix}.history`))
            .send({
                user_id,
                module_id,
                data: JSON.stringify(data),
                create_time: Date.now() + '',
            })
            .then((res) => {
                if (res.body.err === 0) {
                    resolve(res.body.data)
                }
            }, (err) => {
                reject(err)
            })
    })
}

// 获取该模块的历史记录
const getHistory = (matches) => {
    return new Promise((resolve, reject) => {
        superagent.post(`${host}/v1/history/list/1/10`)
           .set('token', encrypt(`${tokenPrefix}.history`))
           .send(matches)
           .then((res) => {
                if (res.body.err === 0) {
                    resolve(res.body.data)
                }
            }, (err) => {
                reject(err)
            })
        })
}

export {
    saveHistory,
    getHistory
}