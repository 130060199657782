import React from 'react';
import { Space } from 'antd'
import { InputNumber } from '@/components';

export default function InputNumWithTitle(props) {
    return (
        <Space direction="vertical" align="start" style={{ display: 'flex' }}>
            <span style={{ fontSize: 12, color: '#999' }}>{props.labelContent}</span>
            <InputNumber
                {...props}
                labelContent=""
            />
        </Space>
    )
}
