import React, { createContext } from 'react'
import pluginsGroup from '@/plugins'
export const GlobalStoreContext = createContext({})

const GlobalStore = ({ children }) => {
    return (
        <GlobalStoreContext.Provider
            value={{
                setPreviewData: ({ baseConfig = {}, code = '', name = '', plugins = [] }) => {
                    localStorage.setItem('tgb_preview_data', JSON.stringify({
                        baseConfig,
                        code,
                        name,
                        plugins,
                    }))
                },
                getPreviewData: () => {
                    let result = localStorage.getItem('tgb_preview_data')
                    if (result) {
                        result = JSON.parse(result)
                        const { plugins } = result
                        plugins.map((plugin) => {
                            Object.values(pluginsGroup).forEach((v, i) => {
                                if (plugin.type === v.type) {
                                    plugin.preview = v.preview
                                }
                            })
                            return plugin
                        })
                        return result
                    }
                    return null
                },
            }}
        >

            {children}
        </GlobalStoreContext.Provider>
    )
}

export default GlobalStore