import React, { useState, useContext, useRef } from 'react'
import { InsertContext, BaseConfigContext } from '@/context'
import { CloseOutlined, DeleteOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'

import CX from 'classnames'
import { SwapOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import {
    ConfigTitle,
} from '@/components'
import { InputNumber3x } from '@/HOC'

import './index.less'

// 右侧组件面板区域
export default function RightContent() {
    const [showLayer, setShowLayer] = useState(false)
    const context = useContext(InsertContext)
    const { maxSize, changeMaxSize } = useContext(BaseConfigContext)
    const scrollElemRef = useRef(null)
    const { exchangePluginOrder, insertList, currentPlugin, deleteInsertPlugin, setCurrentPlugin } = context
    let uniqueArr = insertList.filter((item, index, self) =>index === self.findIndex((t) => t.name === item.name));
    const reverseInsertList = [...uniqueArr].reverse()

    return (
        <div className="right-content-wrap flex-col">
            <div className="main-content">
                {
                    currentPlugin && currentPlugin.insertId
                        ? <currentPlugin.board {...context} />
                        : (
                            <Space direction="vertical" size={16} align="start" style={{ width: '100%', marginLeft: 20 }}>
                                <ConfigTitle
                                    title="画布设置"
                                    detail="待修改"
                                    style={{
                                        paddingTop: 20,
                                    }}
                                />
                                <InputNumber3x
                                    labelContent="高"
                                    defaultValue={maxSize.height}
                                    onChange={(v) => {
                                        changeMaxSize({
                                            height: v
                                        })
                                    }}
                                />
                            </Space>
                        )
                }
            </div>
            <div className="bottom-area items-center justify-center">
                {
                    insertList.length > 0 && (
                        <div className="button items-center justify-center" onClick={() => {
                            setShowLayer(true)
                        }}>
                            <Space>
                                <SwapOutlined className="icon" />
                                管理涂层顺序
                            </Space>
                        </div>
                    )
                }
                {
                    showLayer && (
                        <div className="layer-selector flex-col">
                            <Space className="title-area justify-center" size={36}>
                                <div className="title">管理图层顺序</div>
                                <CloseOutlined className="close" onClick={() => {
                                    setShowLayer(false)
                                }} />
                            </Space>
                            <Space direction="vertical" className="layer-area items-center" size={4} ref={scrollElemRef}>
                                {
                                    // 管理涂层数据
                                    reverseInsertList.map((plugin, index) => (
                                        <div
                                            className="layer flex-row items-center"
                                            key={'right-layer-' + index}
                                        >
                                            <div
                                                className="name"
                                                onClick={() => {
                                                    setCurrentPlugin(plugin)
                                                }}>{plugin.name}</div>
                                            <div className="icon-area flex-row items-center justify-between">
                                                <DeleteOutlined className="delete" onClick={() => {
                                                    deleteInsertPlugin(plugin.insertId)
                                                }} />
                                                <div className="arrow-wrap flex-col">
                                                    <div
                                                        className="box justify-center items-center"
                                                        onClick={() => {
                                                            if (index !== 0) {
                                                                // 因为reverse
                                                                const originIndex = insertList.length - 1 - index
                                                                //当前的位置，要去的位置
                                                                exchangePluginOrder(originIndex, originIndex + 1)
                                                            }
                                                        }}
                                                    >
                                                        <CaretUpOutlined
                                                            className={
                                                                CX({
                                                                    icon: true,
                                                                    disabled: index === 0,
                                                                })
                                                            }

                                                        />
                                                    </div>
                                                    <div
                                                        className="box justify-center items-center"
                                                        onClick={() => {
                                                            if (index !== insertList.length - 1) {
                                                                const originIndex = insertList.length - 1 - index
                                                                exchangePluginOrder(originIndex, originIndex - 1)
                                                            }
                                                        }}
                                                    >
                                                        <CaretDownOutlined
                                                            className={
                                                                CX({
                                                                    icon: true,
                                                                    disabled: index === insertList.length - 1
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Space>
                        </div>
                    )
                }

            </div>
        </div>
    )
}