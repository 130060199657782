import React, { useRef, useEffect, useState, useCallback } from 'react'
import Moveable from 'react-moveable'
import { takeNumberFromStr } from '@/utils/index'
import { flushSync } from 'react-dom'

export default function MoveItem({
    children,
    resize = true,
    drag = true,
    rotate = false,
    keepRatio = false,
    showPoints = true,
    showOrigin = true,
    showBorder = true,
    isActive = true,
    maxWidth = 375,
    maxHeight = 667,
    noWrap = true,
    renderDirections = ["nw", "n", "ne", "w", "e", "sw", "s", "se"],
    onClick = () => {},
    onResize = () => {},
    onDrag = () => {},
    onDragStart = () => {},
    onResizeStart = () => {},
}) {
    const [isChildMount, setIsChildMount] = useState(false)
    const moveAbleRef = useRef(null)
    const childrenRef = useRef(null)
    const moveTuleRef = useRef(null)
    const isRectChange = useRef(false)

    const createChildren = useCallback(() => {
        if (Array.isArray(children)) {
            const list = [...children]
            list[0] = React.cloneElement(list[0], {
                ref: childrenRef,
                ...list[0].props
            })
            return list
        } else {
           
            return React.cloneElement(children, {
                ref: childrenRef,
            })
        }
    }, [children])

    useEffect(() => {
        if (childrenRef.current && isChildMount !== true) {
            setIsChildMount(true)
        }
    }, [childrenRef, isChildMount])
    useEffect(() => {
        if (isRectChange.current === false) {
            if (moveTuleRef.current) {
                moveTuleRef.current.moveable.updateRect()
            }
        }
    }, [children])
    return (
        children && (
            <div ref={moveAbleRef}>
                {createChildren()}
                {
                    isChildMount && (
                        <Moveable
                            ref={moveTuleRef}
                            flushSync={flushSync}
                            checkInput={true}
                            useMutationObserver={true}
                            target={childrenRef.current}
                            // container={container}
                            origin={false}
                            onClick={() => {
                                onClick()
                            }}
                            /* Resize event edges */
                            // edge={false}

                            /* draggable */
                            snappable={false} // 是否初始化磁吸功能
                            snapContainer={moveAbleRef.current}
                            draggable={drag}
                            throttleDrag={1}
                            onDragStart={({ target, clientX, clientY }) => {
                                isRectChange.current = true
                                onDragStart()
                                console.log("onDragStart", target);
                            }}
                            onDrag={({
                                target,
                                beforeDelta, beforeDist,
                                left, top,
                                right, bottom,
                                delta, dist,
                                width, height,
                                transform,
                                clientX, clientY,
                            }) => {
                                const [leftStr, topStr] = transform.split(',')
                                let transLeft = takeNumberFromStr(leftStr)
                                let transTop = takeNumberFromStr(topStr)
                                if (noWrap === false) {
                                    transLeft = transLeft < 0 ? 0 : (transLeft + width > maxWidth ? maxWidth - width : transLeft)
                                    transTop = transTop < 0 ? 0 : (transTop + height > maxHeight ? maxHeight - height : transTop)
                                }
                                target.style.transform = `translate(${transLeft}px, ${transTop}px)`
                                onDrag({ left: transLeft, top: transTop, width, height })
                            }}
                            onDragEnd={({ target, isDrag, clientX, clientY }) => {
                                isRectChange.current = false
                            }}

                            /* When resize or scale, keeps a ratio of the width, height. */
                            keepRatio={keepRatio}

                            /* resizable*/
                            /* Only one of resizable, scalable, warpable can be used. */
                            resizable={resize}
                            // throttleResize={0}
                            onResizeStart={({ target, clientX, clientY }) => {
                                console.log("onResizeStart", target);
                                isRectChange.current = true
                                onResizeStart()
                            }}
                            onResize={({
                                target, width, height,
                                dist, delta, direction,
                                clientX, clientY,
                                transform,
                                drag,
                            }) => {
                                const [leftStr, topStr] = transform.split(',')
                                let transLeft = takeNumberFromStr(leftStr)
                                let transTop = takeNumberFromStr(topStr)
                                if (noWrap === false) {
                                    width = Math.min(width, maxWidth)
                                    height = Math.min(height, maxHeight)
                                    transLeft = transLeft < 0 ? 0 : (transLeft + width > maxWidth ? maxWidth - width : transLeft)
                                    transTop = transTop < 0 ? 0 : (transTop + height > maxHeight ? maxHeight - height : transTop)
                                }
                                target.style.width = `${width}px`;
                                target.style.height = `${height}px`;
                                target.style.transform = `translate(${transLeft}px, ${transTop}px)`
                                onResize({
                                    width,
                                    height,
                                    left: transLeft,
                                    top: transTop,
                                })
                            }}
                            onResizeEnd={({ target, isDrag, clientX, clientY }) => {
                                console.log("onResizeEnd", target, isDrag);
                                isRectChange.current = false
                            }}
                            renderDirections={showPoints && isActive ? renderDirections : []}
                            /* scalable */
                            /* Only one of resizable, scalable, warpable can be used. */
                            scalable={resize}
                            throttleScale={0}
                            onScaleStart={({ target, clientX, clientY }) => {
                                console.log("onScaleStart", target);
                                isRectChange.current = true
                            }}
                            onScale={({
                                target, scale, dist, delta, transform,
                                clientX, clientY,
                            }) => {
                                console.log("onScale scale", scale);
                                target.style.transform = transform;
                            }}
                            onScaleEnd={({ target, isDrag, clientX, clientY }) => {
                                console.log("onScaleEnd", target, isDrag);
                                isRectChange.current = false
                            }}
                            edge={false}
                            zoom={isActive && showBorder ? 1 : 0}
                            /* rotatable */
                            rotatable={rotate}
                            throttleRotate={0}
                            onRotateStart={({ target, clientX, clientY }) => {
                                console.log("onRotateStart", target);
                                isRectChange.current = true
                            }}
                            onRotate={({
                                target,
                                delta, dist,
                                transform,
                                clientX, clientY,
                            }) => {
                                console.log("onRotate", dist);
                                target.style.transform = transform;
                            }}
                            onRotateEnd={({ target, isDrag, clientX, clientY }) => {
                                console.log("onRotateEnd", target, isDrag);
                                isRectChange.current = false
                            }}
                            // Enabling pinchable lets you use events that
                            // can be used in draggable, resizable, scalable, and rotateable.
                            pinchable={true}
                            onPinchStart={({ target, clientX, clientY, datas }) => {
                                // pinchStart event occur before dragStart, rotateStart, scaleStart, resizeStart
                                console.log("onPinchStart");
                                isRectChange.current = true
                            }}
                            onPinch={({ target, clientX, clientY, datas }) => {
                                // pinch event occur before drag, rotate, scale, resize
                                console.log("onPinch");
                            }}
                            onPinchEnd={({ isDrag, target, clientX, clientY, datas }) => {
                                // pinchEnd event occur before dragEnd, rotateEnd, scaleEnd, resizeEnd
                                console.log("onPinchEnd");
                                isRectChange.current = false
                            }}
                        />
                    )
                }

            </div>
        )
    )
}