import { useState, createContext, useCallback } from 'react'
import { getConfigModulesList } from '@/server/moduleManager'
import modules from '@/modules'

export const ModulesContext = createContext({})

// 模块管理
const ModulesProvider = ({ children }) => {
    const [_modules, _setModules] = useState([])
    return (
        <ModulesContext.Provider
            value={{
                modules: _modules,
                initModules: () => {
                    getConfigModulesList().then((res) => {
                        const configModulesName = res.map(r => r.name)
                        const result = []
                        modules.forEach((module) => {
                            const nameIndex = configModulesName.indexOf(module.name)
                            if (nameIndex > -1) {
                                module.module_id = res[nameIndex].module_id
                                result.push(module)
                            }
                        })
                        _setModules(result)
                    })
                },
                findModuleByName: (name) => {
                    return _modules.find(m => m.name === name)
                },
                setModules: _setModules,
            }}
        >
            {children}
        </ModulesContext.Provider>
    )
}

export default ModulesProvider