import React, { useState, createContext } from 'react'
import { saveHistory, getHistory } from '@/server/moduleHistory' 
export const UserInfoContext = createContext({})

// 用户信息
const UserInfo = ({ children }) => {
    const [isLogin, setIsLogin] = useState(false)

    const [userInfo, setUserInfo] = useState({
        user_name: 'TGB官方旗舰店',
        whitelist: [],
        user_id: '',
        shop_id: '',
        password: '',
        create_time: '0',
    })

    return (
        <UserInfoContext.Provider
            value={{
                userInfo,
                setUserInfo: (newInfo) => {
                    if (newInfo.whitelist) {
                        newInfo.whitelist = JSON.parse(newInfo.whitelist)
                    }
                    setUserInfo(Object.assign({}, newInfo))
                },
                useLogin: () => {
                    // 登录并记录下登录信息，用于自动登录和记住密码
                    const login = (account, password, userId, needSave = false) => {
                        localStorage.setItem('tgb_account', account)
                        localStorage.setItem('tgb_password', password)
                        localStorage.setItem('tgb_user_Id', userId)
                        localStorage.setItem('tgb_login_timestamp', Date.now())
                        localStorage.setItem('tgb_remember', needSave)
                        setIsLogin(true)
                    }

                    const logoff = () => {
                        localStorage.removeItem('tgb_login_timestamp')
                        setIsLogin(false)
                    }

                    const getSaveLoginInfo = () => {
                        return {
                            account: localStorage.getItem('tgb_account'),
                            password: localStorage.getItem('tgb_password'),
                            userId: localStorage.getItem('tgb_user_Id'),
                            timestamp: localStorage.getItem('tgb_login_timestamp'),
                            isRemember: localStorage.getItem('tgb_remember'),
                        }
                    }

                    return {
                        login,
                        logoff,
                        getSaveLoginInfo,
                    }
                },
                modulesHistoryGrp: {},
                saveModulesHistory: (module_id, data = {}) => {
                    return new Promise((resolve, reject) => {
                        if (!userInfo.user_id || module_id === '') {
                            reject()
                            return
                        }
                        saveHistory({
                            user_id: userInfo.user_id,
                            module_id,
                            data,
                        }).then((res) => {
                            resolve(res)
                        }).catch((err) => {
                            reject(err)
                        })
                    })
                },
                isLogin,
                setIsLogin,
            }}
        >

            {children}
        </UserInfoContext.Provider>
    )
}

export default UserInfo