import React, { useRef, useContext, Fragment, useCallback } from 'react'

import MoveAble from '@/components/MoveAble'
import AdvArea from './AdvArea'
import { PluginContext, InsertContext, BaseConfigContext } from '@/context'
import './index.less'

const RenderPlugin = React.forwardRef(({ comp = null, plugin, index = 0, tool = {} }, ref) => {
    plugin.currentIndex = index
    return comp ? React.cloneElement(comp(plugin, tool), { ref }) : null
})

// 中间画布区域
export default function DrawContent() {
    const { getPlugin } = useContext(PluginContext)
    const { findTargetByType, insert, insertList, currentPlugin, changeCurrentPluginRect, setCurrentPlugin } = useContext(InsertContext)
    let uniqueArr = insertList.filter((item, index, self) =>index === self.findIndex((t) => t.name === item.name));
    const { maxSize } = useContext(BaseConfigContext)
    const wrapElemRef = useRef(null)
    const startMouseDownPos = useRef({ x: 0, y: 0 })
    const startWrapElemRect = useRef({ left: 0, top: 0 })
    const initScaleValue = useRef(1)
    const isStartMouseDown = useRef(false)
    const isForbidBrowserScale = useRef(false)

    const preventWheel = useCallback((e) => {
        e.preventDefault()
    }, [])

    const forbidBrowserScale = useCallback(() => {
        // 滚动鼠标滑轮放大缩小画布时禁止浏览器缩放
        if (isForbidBrowserScale.current) return
        isForbidBrowserScale.current = true
        document.addEventListener('DOMMouseScroll', preventWheel, { capture: false, passive: false })
        document.addEventListener('wheel', preventWheel, { capture: false, passive: false })
    }, [])

    const allowBrowerScale = useCallback(() => {
        // 允许浏览器缩放
        if (!isForbidBrowserScale.current) return
        isForbidBrowserScale.current = false
        document.removeEventListener('DOMMouseScroll', preventWheel, { capture: false, passive: false })
        document.removeEventListener('wheel', preventWheel, { capture: false, passive: false })
    }, [])

    return (
        <div
            className="draw-content-wrap"
            onWheel={(e) => {
                // 放大缩小画布
                forbidBrowserScale()
                let add = 0
                if (e.deltaY > 2) {
                    // 缩小
                    add -= 0.2
                } else if (e.deltaY < -2) {
                    // 放大
                    add += 0.2
                }
                initScaleValue.current += add
                wrapElemRef.current.style.transform = `scale(${Math.min(Math.max(initScaleValue.current, 0.3), 2)})`
            }}
            onMouseEnter={forbidBrowserScale}
            onMouseLeave={allowBrowerScale}
        >
            <AdvArea />
            <div className="drag-bg"
                onMouseDown={(e) => {
                    // 拖拽画布整体
                    const { offsetLeft, offsetTop } = wrapElemRef.current
                    isStartMouseDown.current = true
                    startMouseDownPos.current.x = e.clientX
                    startMouseDownPos.current.y = e.clientY
                    startWrapElemRect.current.left = offsetLeft
                    startWrapElemRect.current.top = offsetTop
                }}
                onMouseMove={(e) => {
                    if (isStartMouseDown.current === false) return
                    const { left, top } = startWrapElemRect.current
                    const x = left + e.clientX - startMouseDownPos.current.x
                    const y = top + e.clientY - startMouseDownPos.current.y
                    wrapElemRef.current.style.left = x + 'px'
                    wrapElemRef.current.style.top = y + 'px'
                }}

                onMouseUp={() => {
                    isStartMouseDown.current = false
                }}
                onMouseLeave={() => {
                    isStartMouseDown.current = false
                }}
            ></div>
            <div
                className="scale-content"
                ref={wrapElemRef}
                onDragEnter={(e) => {
                    e.preventDefault()
                }}
                onDragOver={(e) => {
                    e.preventDefault()
                }}
                onDrop={(e) => {
                    // 将组件拖进画布中
                    const type = e.dataTransfer.getData('type')
                    if (findTargetByType(type) === null) {
                        const plugin = getPlugin(type)
                        if (plugin) {
                            insert(plugin)
                        }
                    } else {
                        // 提示已经存在组件了
                    }
                }}
                style={{
                    width: maxSize.width,
                    height: maxSize.height,
                }}
            >
                <div className="moveable-content-wrap" onClick={() => {
                    setCurrentPlugin({})
                }}></div>
                {
                    uniqueArr.map((plugin, index) => {
                        return (
                            <Fragment key={plugin.insertId + '' + index}>
                                <MoveAble
                                    container={wrapElemRef.current}
                                    resize={plugin.drawOptions.resize}
                                    drag={plugin.drawOptions.drag}
                                    rotate={plugin.drawOptions.rotate}
                                    keepRatio={plugin.drawOptions.keepRatio}
                                    maxWidth={maxSize.width}
                                    renderDirections={plugin.drawOptions.renderDirections}
                                    maxHeight={maxSize.height}
                                    onClick={() => {
                                        setCurrentPlugin(plugin)
                                    }}
                                    isActive={plugin.insertId === currentPlugin.insertId}
                                    onResizeStart={() => {
                                        setCurrentPlugin(plugin)
                                    }}
                                    onDragStart={() => {
                                        setCurrentPlugin(plugin)
                                    }}
                                    onResize={({ width, height, left, top }) => {
                                        changeCurrentPluginRect({ width, height, left, top })
                                    }}
                                    onDrag={({ top, left }) => {
                                        changeCurrentPluginRect({ top, left })
                                    }}
                                >
                                    <RenderPlugin comp={plugin.render} plugin={plugin} tool={{changeCurrentPluginRect}} />
                                </MoveAble>
                                {
                                    plugin.subPlugins.map((sub, index) => {
                                        return (
                                            <MoveAble
                                                key={index + 'sub'}
                                                showPoints={plugin.id === currentPlugin.id}
                                                resize={plugin.drawOptions.subOptions[index]?.resize}
                                                drag={plugin.drawOptions.subOptions[index]?.drag}
                                                rotate={plugin.drawOptions.subOptions[index]?.rotate}
                                                keepRatio={plugin.drawOptions.subOptions[index]?.keepRatio}
                                                renderDirections={plugin.drawOptions.subOptions[index].renderDirections}
                                                maxWidth={maxSize.width}
                                                maxHeight={maxSize.height}
                                                isActive={plugin.insertId === currentPlugin.insertId}
                                                onClick={() => {
                                                    setCurrentPlugin(plugin)
                                                }}
                                                onResizeStart={() => {
                                                    setCurrentPlugin(plugin)
                                                }}
                                                onDragStart={() => {
                                                    setCurrentPlugin(plugin)
                                                }}
                                                onDrag={({ top, left }) => {
                                                    changeCurrentPluginRect({ top, left }, index + 1)
                                                }}
                                                onResize={({ width, height, top, left }) => {
                                                    changeCurrentPluginRect({ width, height, top, left }, index + 1)
                                                }}
                                            >
                                                <RenderPlugin comp={sub} plugin={plugin} index={index} tool={{ changeCurrentPluginRect }} />
                                            </MoveAble>
                                        )
                                    })
                                }
                            </Fragment>

                        )
                    })
                }
            </div>
        </div>
    )
}