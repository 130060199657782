const convertNumber = (val) => {
    return !isNaN(Number(val))  ? Number(val) : 0
}

const takeNumberFromStr = (str) => {
    const regex = /[^\-\?0-9.]/ig
    const result = str.replace(regex, '')
    return Number(result)
}

const getYYMMDDHHMM = (timestamp) => {
    timestamp = Number(timestamp);
    let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return Y + M + D + h + m + s;
}

const getDateDiffDHMS = (ori, tar) => {
    let diff = Math.abs(tar - ori) / 1000
    const d = Math.floor(diff / (24 * 60 * 60))
    const h = parseInt(diff / 60 / 60 % 24)
    const m = parseInt(diff / 60 % 60)
    const s = parseInt(diff % 60)
    const __t = (n) => {
        return n < 10 ? `0${n}` : n
    }
    return [__t(d), __t(h), __t(m), __t(s)]
}

// 通过Url获取该图片的原始尺寸
const computeMediaUrlRect = (url) => {
    return new Promise((resolve, reject) => {
        let _timer = null
        clearInterval(_timer)
        let _intervalFn = () => {}
        // 默认京东的视频链接mp4格式的
        if (url.indexOf('.mp4') > -1) {
            const video = document.createElement('video')
            video.setAttribute('src', url)
            _intervalFn = () => {
                if (video.videoWidth > 0 || video.videoHeight > 0) {
                    resolve({
                        width: video.videoWidth, 
                        height: video.videoHeight,
                        ratio: video.videoWidth / video.videoHeight
                    })
                    clearInterval(_timer)
                }
            }
        } else {
            const img = new Image()
            img.src = url
            _intervalFn = () => {
                if (img.width > 0 || img.height > 0) {
                    resolve({
                        width: img.width, 
                        height: img.height,
                        ratio: img.width / img.height
                    })
                    clearInterval(_timer)
                }
            }
            img.onerror = reject
        }
        _timer = setInterval(_intervalFn, 40)
    })
}

export {
    convertNumber,
    takeNumberFromStr,
    getYYMMDDHHMM,
    getDateDiffDHMS,
    computeMediaUrlRect,
}